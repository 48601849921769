import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ProjectImageCarousel from './ProjectImageCarousel'; // Import the new component

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const badgeHeight='50px';
  const badgeWidht='170px';
  
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // This would typically come from an API or context
  // For now we'll use the same data as in Projects.js
  const projectsData = [
    {
      id: 0,
      title: "Unfollowers- No Login Required",
      description: "Unfollowers is a privacy-focused tool designed to help users analyze their Instagram follower activity securely. Unlike other apps, it does not require a login, ensuring that your personal data remains safe. By processing JSON files exported from Instagram's account center, the app provides insights into who follows and unfollows you.",
      tech: ["React Native", "JavaScript", "IOS","Android"],
      color: "#64ffda",
      fullDescription: "Unfollowers is a privacy-focused tool designed to help users analyze their Instagram follower activity securely. Unlike other apps, it does not require a login, ensuring that your personal data remains safe. By processing JSON files exported from Instagram's account center, the app provides insights into who follows and unfollows you.",
      features: [
        "No Login Required – Ensures privacy and security by working only with user-provided JSON files.",
        "Follower Tracking – Easily identify users who have unfollowed you.",
        "User-Friendly Interface – Simple file upload system for quick analysis.",
        "Account Management Insights – Helps users understand follower trends and engagement.",
        "Fast & Efficient Processing – Provides instant results with minimal effort."
        
      ],
      challenges: "Ensuring accurate data extraction and comparison from Instagram's JSON files was a key challenge. Additionally, optimizing the app for mobile performance while handling potentially large datasets required efficient parsing and UI responsiveness techniques.",
      outcome: "This app empowers users with a secure and risk-free way to monitor their follower activity without compromising their Instagram credentials. By providing clear insights into follower trends, it helps users manage their online presence and engagement strategies more effectively."
    },
    {
      id: 1,
      title: "Stutent Management System",
      description: "Tool that leverages NLP to create unique content for blogs and social media.",
      tech: [".NET CORE", "Postgresql", "Blazor", "React Native"],
      color: "#7928ca",
      fullDescription: "The Student Management System (formerly ÖğrenciBis) is a comprehensive platform designed to help schools and private courses manage students, teachers, and academic processes efficiently. It provides an intuitive admin panel for managing classes, assignments, exams, and more. A mobile version allows teachers to track attendance and assign homework on the go.",
      features: [
        "Comprehensive Admin Panel – Manage students, teachers, and classes effortlessly.",
        "Exam & Homework Management – Assign and review student work in a structured way.",
        "Attendance Tracking – Monitor student attendance via the mobile app.",
        "Role-Based Access Control – Ensure secure access with different permission levels.",
        "Blazor & MudBlazor UI – Modern, interactive, and responsive user experience.",
        "PostgreSQL Database – Scalable and efficient data management."
      ],
      challenges: "Building a seamless experience across web and mobile platforms while ensuring real-time data synchronization was a significant challenge. Additionally, optimizing performance for large datasets in PostgreSQL required strategic indexing and query optimization.",
      outcome: "The system enhances school and course management by helping administrators keep track of students' academic progress, attendance, and assignments more effectively. Teachers can easily manage homework and exams, while students benefit from a structured and transparent system that improves their learning experience."
    },
    {
      id: 2,
      title: "CodeWords Party Board Game",
      description: "Instant messaging application with end-to-end encryption and file sharing.",
      tech: ["React Native", "QR", "IOS","Android"],
      color: "#ff4d4d",
      fullDescription: "Code Words is a unique offline multiplayer word association game that leverages QR codes for seamless connectivity. Instead of traditional online matchmaking, the game allows players to join sessions by scanning a QR code generated by the host. This method enables quick, internet-free gameplay, making it perfect for social gatherings.",
      features: [
        "Offline Multiplayer with QR Code Syncing – No internet required; players join a game by scanning a QR code generated by the host.",
        "Instant Game Setup – The host selects words, generates a QR code, and others scan it to start playing within seconds.",
        "Seamless Word Sharing – QR codes store all necessary game data, ensuring every player sees the same words and rules.",
        "Strategic Team-Based Gameplay – Players work together, using clever clues to uncover the correct words while avoiding traps.",
        "Bilingual Support – Play in English or Turkish, making it accessible to a wider audience."

      ],
      challenges: "Designing a reliable QR code encoding and decoding system to store game data efficiently was a key challenge. Ensuring smooth data synchronization between players without an internet connection required optimized QR generation and scanning processes.",
      outcome: "Code Words provides a fun and accessible social gaming experience without the need for online connectivity. By using QR codes for instant game sharing, players can quickly set up and enjoy competitive wordplay anywhere, making it an ideal party game."
    },
    {
      id: 3,
      title: "SeeYourSky",
      description: "Mobile app that monitors workouts and provides personalized fitness plans.",
      tech: ["React", "RestApi", "JavaScript"],
      color: "#FFD600",
      fullDescription: "SeeYourSky is a web application that allows users to explore NASA’s Astronomy Picture of the Day (APOD) for any given date. By leveraging NASA's Open API, the app retrieves stunning space imagery along with detailed explanations, offering users a daily glimpse into the cosmos.",
      features: [
        "Explore Space Daily – View NASA’s Astronomy Picture of the Day and its official description.",
        "Date-Based Search – Select any date to see the corresponding APOD image and details.",
        "NASA Open API Integration – Fetches high-quality space imagery directly from NASA's database.",
        "Responsive Design – Works seamlessly across desktop and mobile devices.",
        "Minimal & Fast UI – Built with React for a smooth and interactive experience."
        
      ],
      challenges: "Creating an algorithm that could generate truly personalized workout plans based on multiple data points was technically challenging. We also needed to ensure the app remained responsive while processing large amounts of fitness data.",
      outcome: "Users who follow the app's recommended plans report 35% better adherence to their fitness routines compared to self-directed workouts."
    },
    {
      id: 4,
      title: "Portfolio Website",
      description: "Creative showcase of my development skills and professional projects.",
      tech: ["HTML5", "CSS3", "JavaScript"],
      color: "#00C6FF",
      fullDescription: "This portfolio website serves as a showcase for my development skills and professional projects. It features a modern, responsive design with interactive elements that highlight my technical capabilities while providing visitors with an engaging user experience.",
      features: [
        "Interactive project showcase",
        "Responsive design for all devices",
        "Animated UI elements",
        "Contact form with validation",
        "Performance optimized loading",
        "SEO-friendly structure",
        "Accessibility compliance"
      ],
      challenges: "Creating a visually impressive site while maintaining fast load times required careful optimization. Balancing creative design elements with professional presentation was also a key consideration.",
      outcome: "The portfolio has generated multiple client inquiries and job opportunities, with visitors spending an average of 3.5 minutes exploring the site."
    }
  ];

  const project = projectsData[parseInt(id)];

  const handleBackToProjects = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToProjects: true } });
  };
  const renderDownloadButtons = () => {
    if (project.id === 0) { // Unfollowers project
      return (
        <div style={{ 
          display: 'flex', 
          gap: '1rem', 
          marginTop: '1.5rem', 
          justifyContent: 'start' 
        }}>
          <a 
            href="https://apps.apple.com/us/app/unfollowers-no-login-required/id6739170412" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              height: badgeHeight,
              backgroundImage: 'url(https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: badgeWidht
            }}
          >
            <span style={{ visibility: 'hidden' }}>Download on the App Store</span>
          </a>
          {/* <a 
            href="https://play.google.com/store/apps/details?id=com.yourapp.unfollowers" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              height: '50px',
              backgroundImage: 'url(https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '170px'
            }}
          >
            <span style={{ visibility: 'hidden' }}>Get it on Google Play</span>
          </a> */}
        </div>
      );
    }
    
    if (project.id === 2) { // CodeWords project
      return (
        <div style={{ 
          display: 'flex', 
          gap: '1rem', 
          marginTop: '1.5rem', 
          justifyContent: 'start' 
        }}>
          <a 
            href="https://apps.apple.com/us/app/code-words-board-game/id6654889746" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              height: badgeHeight,
              backgroundImage: 'url(https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: badgeWidht
            }}
          >
            <span style={{ visibility: 'hidden' }}>Download on the App Store</span>
          </a>
          {/* <a 
            href="https://play.google.com/store/apps/details?id=com.yourapp.codewords" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              height: '50px',
              backgroundImage: 'url(https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '170px'
            }}
          >
            <span style={{ visibility: 'hidden' }}>Get it on Google Play</span>
          </a> */}
        </div>
      );
    }

    if (project.id === 3) { // Fitness Tracker project
      return (
        <div style={{ 
          display: 'flex', 
          gap: '1rem', 
          marginTop: '1.5rem', 
          justifyContent: 'start' 
        }}>
          <a 
            href="https://see-your-sky.vercel.app/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="btn"
            style={{
              textDecoration: 'none',
              display: 'inline-block',
              padding: '10px 20px',
              
              
              borderRadius: '5px',
              fontWeight: 'bold',
              transition: 'opacity 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.opacity = '0.8'}
            onMouseOut={(e) => e.target.style.opacity = '1'}
          >
            Visit Site
          </a>
        </div>
      );
    }
    
    return null;
  };
  if (!project) {
    return (
      <div className="project-detail-container" style={{ padding: '10rem 2rem', textAlign: 'center' }}>
        <h2>Project not found</h2>
        <Link to="/" className="btn">Back to Home</Link>
      </div>
    );
  }

  return (
    <div className="project-detail-container" style={{ padding: '10rem 2rem', maxWidth: '1000px', margin: '0 auto' }}>
      <div className="back-button" style={{ marginBottom: '2rem' }}>
        <a href="/#projects" className="btn" onClick={handleBackToProjects}>Back to Projects</a>
      </div>
      
      <div className="project-header" style={{ 
        borderLeft: `5px solid ${project.color}`, 
        paddingLeft: '2rem',
        marginBottom: '3rem'
      }}>
        <h1 style={{ fontSize: '3rem', marginBottom: '1rem' }}>{project.title}</h1>
        <div className="tech-stack" style={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
          {project.tech.map((tech, i) => (
            <span key={i} className="tech">{tech}</span>
          ))}
        </div>
      </div>

      
      <ProjectImageCarousel projectId={parseInt(id)} />
      
      <div className="project-content">
        <section style={{ marginBottom: '3rem' }}>
          <h2 style={{ color: project.color, marginBottom: '1.5rem' }}>Project Overview</h2>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>{project.fullDescription}</p>
          {renderDownloadButtons()}
        </section>
        
        <section style={{ marginBottom: '3rem' }}>
          <h2 style={{ color: project.color, marginBottom: '1.5rem' }}>Key Features</h2>
          <ul style={{ paddingLeft: '2rem' }}>
            {project.features.map((feature, index) => (
              <li key={index} style={{ marginBottom: '0.5rem', fontSize: '1.1rem' }}>{feature}</li>
            ))}
          </ul>
        </section>
        
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem' }}>
          <section>
            <h2 style={{ color: project.color, marginBottom: '1.5rem' }}>Technical Challenges</h2>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>{project.challenges}</p>
          </section>
          
          <section>
            <h2 style={{ color: project.color, marginBottom: '1.5rem' }}>Outcome</h2>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>{project.outcome}</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;