// Create a new file called AllProjects.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function AllProjects() {
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // This would typically come from an API or context
  // For now we'll use the same data as in Projects.js
  const projectsData = [
    {
      id: 0,
      title: "Unfollowers- No Login",
      description: "A secure Instagram analytics tool that tracks follower activity using JSON files—no login required.",
      tech: ["React Native", "IOS", "Android"],
      color: "#64ffda"
    },
    {
      id: 1,
      title: "Student Management",
      description: "Management system for schools and private courses. Includes admin tools, students, teachers, homework, and exams, attendance.",
      tech: [".NET Core", "Blazor", "Postgresql"],
      color: "#7928ca"
    },
    {
      id: 2,
      title: "CodeWords",
      description: "A team-based word association game that challenges players to give and decipher clever clues to uncover hidden words.",
      tech: ["React Native", "IOS", "QR"],
      color: "#ff4d4d"
    },
    {
      id: 3,
      title: "SeeYourSky",
      description: "An API project that integrates NASA's Open API to fetch and display the Astronomy Picture of the Day (APOD) for a given date.",
      tech: ["React", "RestfulApi", "API"],
      color: "#FFD600"
    },
    {
      id: 4,
      title: "Portfolio Website",
      description: "Creative showcase of my development skills and professional projects.",
      tech: ["HTML5", "CSS3", "JavaScript"],
      color: "#00C6FF"
    }
    // // Add more projects for the "all projects" page
    // {
    //   id: 5,
    //   title: "Weather App",
    //   description: "Real-time weather forecasting with location-based services.",
    //   tech: ["JavaScript", "OpenWeather API", "Geolocation"],
    //   color: "#FF9900"
    // },
    // {
    //   id: 6,
    //   title: "Task Management System",
    //   description: "Collaborative tool for team productivity and project organization.",
    //   tech: ["Angular", "Express", "PostgreSQL"],
    //   color: "#28CA7A"
    // },
    // {
    //   id: 7,
    //   title: "Music Streaming Service",
    //   description: "Digital audio platform with personalized recommendations.",
    //   tech: ["React", "Node.js", "AWS S3"],
    //   color: "#9B59B6"
    // }
  ];

  return (
    <div className="all-projects-container" style={{ 
      padding: '10rem 2rem', 
      maxWidth: '1200px', 
      margin: '0 auto' 
    }}>
      <div className="back-button" style={{ marginBottom: '2rem' }}>
        <Link to="/" className="btn">Back to Home</Link>
      </div>
      
      <h1 className="section-title">All Projects</h1>
      <p style={{ 
        textAlign: 'center', 
        marginBottom: '3rem', 
        fontSize: '1.2rem', 
        maxWidth: '700px', 
        margin: '0 auto 3rem' 
      }}>
        A complete collection of my development work, showcasing various technologies and solutions.
      </p>
      
      <div className="projects-grid" style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
        gap: '2rem' 
      }}>
        {projectsData.map((project) => (
          <Link 
            to={`/project/${project.id}`} 
            key={project.id}
            style={{ textDecoration: 'none' }}
          >
            <div className="project-card" style={{ 
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '10px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              padding: '1.5rem',
              transition: 'transform 0.3s, box-shadow 0.3s',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              overflow: 'hidden'
            }}>
              <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '5px', 
                backgroundColor: project.color 
              }}></div>
              
              <div>
              <h3 style={{ marginTop: '0.5rem', marginBottom: '1rem', color: 'var(--text-color)' }}>{project.title}</h3>
              <p style={{ fontSize: '0.95rem', opacity: 0.9, color: 'var(--text-color)' }}>{project.description}</p>
              </div>
              
              <div className="tech-stack" style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '0.5rem', 
                marginTop: '1.5rem',
                color: 'var(--text-color)'
              }}>
                {project.tech.map((tech, i) => (
                  <span key={i} className="tech">{tech}</span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AllProjects;