import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Projects from './components/Projects';
import ProjectDetail from './components/ProjectDetail';
import About from './components/About';
import Experience from './components/Experience'; // Import the new component
import Contact from './components/Contact';
import Footer from './components/Footer';
import AllProjects from './components/AllProjects';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import './index.css';
import { Analytics } from '@vercel/analytics/react';


// Create a separate component for the home page that can handle scrolling
function HomePage() {
  const location = useLocation();
  
  useEffect(() => {
    // If we have state with scrollToProjects set to true, scroll to projects section
    if (location.state && location.state.scrollToProjects) {
      const projectsSection = document.getElementById('projects');
      if (projectsSection) {
        projectsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Header />
      <Projects />
      <About />
      <Experience /> {/* Add the Experience component here */}
      <Contact />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/project/:id" element={<ProjectDetail />} />
          <Route path="/all-projects" element={<AllProjects />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
        <Footer />
        
      </div>
      <Analytics />
    </Router>
    
  );
}

export default App;