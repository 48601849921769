import React from 'react';

function Contact() {
  const socialLinks = [
    { name: "GitHub", url: "https://github.com/voiicez" },
    { name: "LinkedIn", url: "https://www.linkedin.com/in/behlulcan" },
    { name: "X", url: "https://x.com/behlulcansahin" },
    
  ];

  return (
    <section id="contact" className="contact-container">
      <h2 className="section-title">Get In Touch</h2>
      <div className="contact-info">
        <p>I'm always open to discussing new projects, creative ideas or opportunities to be part of your vision.</p>
        <a href="mailto:behlulcansahin@gmail.com" className="btn">Say Hello</a>
        <div className="social-links">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} className="social-link">{link.name}</a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Contact;