import React from 'react';

function Experience() {
  const experiences = [
    {
      id: 1,
      company: "KAZANCI HOLDING Inc",
      position: "Software Developer",
      duration: "2023 - Present",
      description: "Developed and maintained server-side enterprise applications using .NET Framework and WebForms, ensuring seamless integration with existing company systems. Led the API-first transformation of legacy applications, improving scalability and maintainability. Worked on server management, versioning strategies, and dependency resolution to maintain compatibility across different environments. Actively collaborated with teams through Teams and other collaboration tools, streamlining communication and project management within the organization.",
      technologies: [".NET Framework", ".NET Core", "Webforms","Devops","React"]
    },
    {
      id: 2,
      company: "BIG TEKNOLOJI Ltd.",
      position: "Full Stack Developer",
      duration: "2022 - 2023",
      description: "Built and maintained multiple client applications. Developed RESTful APIs and integrated third-party services while optimizing database performance.",
      technologies: [".NET", "Node.js", "PostgreSQL","Docker","React Native"]
    },
    {
      id: 3,
      company: "ANKARA UNIVERSITY",
      position: "Bachelor Degree",
      duration: "2017 - 2022",
      description: "Created responsive web interfaces for various clients. Collaborated closely with design team to implement pixel-perfect designs and animations.",
      technologies: ["HTML5", "CSS3", "JavaScript"]
    }
  ];

  return (
    <section id="experience" className="experience-container">
      <h2 className="section-title">Previous Experience</h2>
      <div className="timeline">
        {experiences.map((exp) => (
          <div key={exp.id} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-header">
                <h3>{exp.position}</h3>
                <span className="company">{exp.company}</span>
                <span className="duration">{exp.duration}</span>
              </div>
              <p className="description">{exp.description}</p>
              <div className="tech-used">
                {exp.technologies.map((tech, index) => (
                  <span key={index} className="tech-badge">{tech}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Experience;