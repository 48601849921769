import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UnfollowersImg from '../images/unfollowersAvatar.png';
import CodeWordsImg from '../images/codewordsAvatar.png';
import PortfolioAvatar from '../images/portfolioAvatar.png';

function Projects() {
  const navigate = useNavigate();
  
  const projectsData = [
    {
      id: 0,
      title: "Unfollowers- No Login",
      description: "A secure Instagram analytics tool that tracks follower activity using JSON files—no login required.",
      tech: ["React Native", "IOS", "Android"],
      color: "#64ffda",
      icon:"A",
      image: UnfollowersImg
    },
    {
      id: 1,
      title: "Student Management",
      description: "Management system for schools and private courses. Includes admin tools, students, teachers, homework, and exams, attendance.",
      tech: [".NET Core", "Blazor", "Postgresql"],
      color: "#7928ca"
    },
    {
      id: 2,
      title: "CodeWords",
      description: "A team-based word association game that challenges players to give and decipher clever clues to uncover hidden words.",
      tech: ["React Native", "IOS", "QR"],
      color: "#ff4d4d",
      image: CodeWordsImg
    },
    {
      id: 3,
      title: "SeeYourSky",
      description: "An API project that integrates NASA's Open API to fetch and display the Astronomy Picture of the Day (APOD) for a given date.",
      tech: ["React", "RestfulApi", "API"],
      color: "#FFD600"
    },
    {
      id: 4,
      title: "Portfolio Website",
      description: "Creative showcase of my development skills and professional projects.",
      tech: ["HTML5", "CSS3", "JavaScript"],
      color: "#00C6FF",
      image: PortfolioAvatar
    }
    
  ];

  const svgRef = useRef(null);
  const projectsRef = useRef([]);

  const handleProjectClick = (id) => {
    navigate(`/project/${id}`);
  };

  useEffect(() => {
    const drawConnections = () => {
      if (!svgRef.current) return;
      
      const svg = svgRef.current;
      // Clear previous connections
      svg.innerHTML = '';
      
      // Get SVG dimensions
      const svgRect = svg.getBoundingClientRect();
      
      // Set viewBox
      svg.setAttribute('viewBox', `0 0 ${svgRect.width} ${svgRect.height}`);
      
      // Connect each project to others
      projectsRef.current.forEach((project, i) => {
        if (!project) return;
        
        const rect1 = project.getBoundingClientRect();
        
        // Center point of the first project relative to SVG
        const x1 = rect1.left + rect1.width / 2 - svgRect.left;
        const y1 = rect1.top + rect1.height / 2 - svgRect.top;
        
        // Connect to the next project (circular)
        const nextIdx = (i + 1) % projectsRef.current.length;
        const nextProject = projectsRef.current[nextIdx];
        
        if (!nextProject) return;
        
        const rect2 = nextProject.getBoundingClientRect();
        
        // Center point of the second project relative to SVG
        const x2 = rect2.left + rect2.width / 2 - svgRect.left;
        const y2 = rect2.top + rect2.height / 2 - svgRect.top;
        
        // Create path
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        
        // Control points for curve
        const ctrlX = (x1 + x2) / 2;
        const ctrlY = Math.min(y1, y2) - 50;
        
        // Define path
        path.setAttribute('d', `M ${x1} ${y1} Q ${ctrlX} ${ctrlY}, ${x2} ${y2}`);
        path.setAttribute('stroke', projectsData[i].color);
        path.setAttribute('stroke-width', '2');
        path.setAttribute('fill', 'none');
        path.setAttribute('stroke-dasharray', '5,5');
        
        // Add glow effect
        const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
        filter.setAttribute('id', `glow-${i}`);
        
        const feGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', 'feGaussianBlur');
        feGaussianBlur.setAttribute('stdDeviation', '2');
        feGaussianBlur.setAttribute('result', 'blur');
        
        filter.appendChild(feGaussianBlur);
        svg.appendChild(filter);
        
        path.setAttribute('filter', `url(#glow-${i})`);
        
        // Animation
        const animate = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
        animate.setAttribute('attributeName', 'stroke-dashoffset');
        animate.setAttribute('from', '0');
        animate.setAttribute('to', '20');
        animate.setAttribute('dur', '1s');
        animate.setAttribute('repeatCount', 'indefinite');
        
        path.appendChild(animate);
        svg.appendChild(path);
      });
    };
    
    // Initial draw
    drawConnections();
    
    // Redraw on window resize
    window.addEventListener('resize', drawConnections);
    
    return () => {
      window.removeEventListener('resize', drawConnections);
    };
  }, []);

  
return (
    <section id="projects" className="projects-container">
      <h2 className="section-title">My Projects</h2>
      <div className="projects">
        {projectsData.map((project, index) => (
          <div 
            key={index} 
            className="project"
            ref={el => projectsRef.current[index] = el}
            onClick={() => handleProjectClick(project.id)}
            style={{ cursor: 'pointer' }}
            role="button"
            aria-label={`View details for ${project.title}`}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleProjectClick(project.id);
              }
            }}
          >
            <div className="project-icon-container">
              <div className="project-icon-wrapper">
                <img 
                  src={project.image} 
                  alt={`${project.title} project icon`} 
                  className="project-icon-image"
                />
              </div>
            </div>
            <div>
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
            <div className="tech-stack">
              {project.tech.map((tech, i) => (
                <span key={i} className="tech">{tech}</span>
              ))}
            </div>
          </div>
        ))}
        
        {/* Add the "Other Projects" card */}
        <div 
          className="project other-projects"
          onClick={() => navigate('/all-projects')}
          style={{ 
            cursor: 'pointer',
            top: '500px',
            right: '40%'
          }}
          role="button"
          aria-label="View all projects"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              navigate('/all-projects');
            }
          }}
        >
          <div>
            <h3>Other Projects</h3>
            <p>View a complete collection of my development work.</p>
          </div>
          <div className="tech-stack">
              
                <span className="tech">{"AI"}</span>
                <span className="tech">{"Mobile"}</span>
                <span className="tech">{"Games"}</span>
                <span className="tech">{"Ideas"}</span>
              
            </div>
        </div>
        
        <svg className="connections" ref={svgRef}></svg>
      </div>
    </section>
  );
}

export default Projects;