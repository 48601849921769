import React from 'react';

function About() {
  const skillCategories = [
    {
      title: "Frontend",
      skills: ["JavaScript", "React", "Blazor"]
    },
    {
      title: "Backend",
      skills: [".NET", "ASP.NET", "Node.js", "Express"]
    },
    {
      title: "Focused",
      skills: ["AI", "AI Assistants","AI Tools","Mobile Apps","Projects"]
    },
    {
      title: "Tools & Others",
      skills: ["Git", "RESTful APIs", "Teams", "DevOps", "Unity", "VR/AR", "Agile"]
    }
  ];

  return (
    <section id="about" className="about-container">
      <h2 className="section-title">About Me</h2>
      <div className="about-content">
        <div className="about-text">
          <h3>Who I Am</h3>
          <p>I am a 26-year-old software developer with three years of professional experience in the field. I specialize in backend development, primarily focusing on .NET Core, and I am continuously improving my skills to become an expert in this area.</p>
          <p>In addition, I have experience in front-end development, particularly with React, Blazor, and React Native, and I actively develop projects using these technologies. With a strong foundation in full-stack development, I play a key role in modern web and mobile application development processes.</p>
          <p>Recently, I have been focusing on artificial intelligence (AI), working on OpenAI APIs, AI-powered chatbots, and data analysis solutions. Developing AI-driven software solutions and deepening my expertise in this field are among my career goals.</p>
        <p>I am a problem-solving-oriented developer who values teamwork and is eager to learn. My passion for technology, which started at a young age, continues to drive me in my professional career as I strive to create innovative projects and stay adaptable to evolving technologies.</p>
        </div>
        <div className="skills">
          <h3>My Skills</h3>
          {skillCategories.map((category, index) => (
            <div key={index} className="skill-category">
              <h4>{category.title}</h4>
              <div className="skill-list">
                {category.skills.map((skill, i) => (
                  <span key={i} className="skill-item">{skill}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;