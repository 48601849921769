import React from 'react';

function Header() {
  const scrollToSection = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <header id="home">
      <div className="hero">
        <h1>Behlülcan Şahin</h1>
        <p>Full-Stack Developer & Problem Solver</p>
        <a href="#contact" className="btn" onClick={(e) => {
          e.preventDefault();
          scrollToSection('#contact');
        }}>Get In Touch</a>
      </div>
    </header>
  );
}

export default Header;