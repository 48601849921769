import React, { useState, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const ProjectImageCarousel = ({ projectId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  // Mock image data - replace this with actual image paths for your projects
  useEffect(() => {
    // In a real implementation, you might fetch these from an API
    const projectImages = {
      0: [ // Unfollowers app
        "/images/projects/unfollowers/1.jpg"
        
      ],
      1: [ // Student Management System
        "/images/projects/student-system/1.png",
        "/images/projects/student-system/2.png",
        "/images/projects/student-system/3.png",
        "/images/projects/student-system/4.png",
        "/images/projects/student-system/5.png",
      ],
      2: [ // CodeWords Party
        "/images/projects/codewords/game-screen.png",
        "/images/projects/codewords/qr-code.png",
        "/images/projects/codewords/team-view.png",
      ],
      3: [ // Fitness Tracker
        "/images/projects/seeyoursky/1.png",
        "/images/projects/seeyoursky/2.png",
        "/images/projects/seeyoursky/3.png",
      ],
      4: [ // Portfolio Website
        "/images/projects/portfolio/home.png",
        "/images/projects/portfolio/projects.png",
        "/images/projects/portfolio/contact.png",
      ],
    };

    // Set images for current project or use placeholders if not found
    const projectSpecificImages = projectImages[projectId] || [
      "/api/placeholder/800/450",
      "/api/placeholder/800/450",
      "/api/placeholder/800/450"
    ];
    
    setImages(projectSpecificImages);
    setLoading(false);
  }, [projectId]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  if (loading) {
    return <div className="carousel-loading">Loading images...</div>;
  }

  if (images.length === 0) {
    return null;
  }

  return (
    <div className="project-carousel" style={{
      position: 'relative',
      marginBottom: '3rem',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
    }}>
      <div className="carousel-container" style={{
        position: 'relative',
        height: '400px',
        backgroundColor: '#0f1118'
      }}>
        <img 
          src={images[currentIndex]} 
          alt={`Project screenshot ${currentIndex + 1}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        
        <button 
          onClick={prevSlide}
          className="carousel-control prev"
          style={{
            position: 'absolute',
            left: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 2
          }}
        >
          <FiChevronLeft size={24} />
        </button>
        
        <button 
          onClick={nextSlide}
          className="carousel-control next"
          style={{
            position: 'absolute',
            right: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 2
          }}
        >
          <FiChevronRight size={24} />
        </button>
      </div>
      
      <div className="carousel-indicators" style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        backgroundColor: '#0f1218'
      }}>
        {images.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: currentIndex === index ? '#000' : '#ccc',
              margin: '0 5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectImageCarousel;