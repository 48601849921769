import React from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  // Sample blog posts (you can replace this with actual blog post data)
  const blogPosts = [
    // {
    //   id: 1,
    //   title: "My Journey into Web Development",
    //   excerpt: "A personal story about how I transitioned from a curious learner to a professional developer...",
    //   date: "March 1, 2025",
    //   readTime: "5 min read"
    // },
    // {
    //   id: 2,
    //   title: "Exploring AI and Machine Learning",
    //   excerpt: "Insights into the fascinating world of artificial intelligence and its potential to transform technology...",
    //   date: "February 15, 2025",
    //   readTime: "7 min read"
    // },
    // {
    //   id: 3,
    //   title: "Building Scalable React Applications",
    //   excerpt: "Best practices and architectural considerations for creating maintainable React projects...",
    //   date: "January 20, 2025",
    //   readTime: "6 min read"
    // }
  ];

  return (
    <div className="blog-container">
      <div className="blog-header">
        <h1 className="section-title">Blog</h1>
        <p>Sharing thoughts, experiences, and technical insights</p>
      </div>
      
      <div className="blog-posts">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-post-preview">
            <Link to={`/blog/${post.id}`} className="blog-post-link">
              <h2>{post.title}</h2>
              <div className="blog-post-meta">
                <span>{post.date}</span>
                <span className="read-time">{post.readTime}</span>
              </div>
              <p>{post.excerpt}</p>
              <span className="read-more">Read More →</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;