import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const scrollToSection = (id) => {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  return (
    <nav>
      <Link to="/" className="logo">BEHLUL.DEV</Link>
      <div className="nav-links">
        {isHomePage ? (
          <>
            <a href="#home" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#home');
            }}>Home</a>
            <a href="#projects" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#projects');
            }}>Projects</a>
            <a href="#about" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#about');
            }}>About</a>
            <a href="#experience" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#experience');
            }}>Experience</a>
            <Link to="/blog">Blog</Link>
            <a href="#contact" onClick={(e) => {
              e.preventDefault();
              scrollToSection('#contact');
            }}>Contact</a>
          </>
        ) : (
          <>
            <Link to="/">Home</Link>
            <Link to="/#projects" onClick={() => setTimeout(() => scrollToSection('#projects'), 100)}>Projects</Link>
            <Link to="/#about" onClick={() => setTimeout(() => scrollToSection('#about'), 100)}>About</Link>
            <Link to="/#experience" onClick={() => setTimeout(() => scrollToSection('#experience'), 100)}>Experience</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/#contact" onClick={() => setTimeout(() => scrollToSection('#contact'), 100)}>Contact</Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;