import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function BlogPost() {
  const navigate = useNavigate();

  // Sample blog post content with added images
  const blogPosts = {
    1: {
      title: "My Journey into Web Development",
      date: "March 1, 2025",
      readTime: "5 min read",
      image: "/api/placeholder/1200/600", // Replace with actual image path
      content: [
        "My journey into web development began with a simple curiosity. As a teenager, I was fascinated by how websites and applications worked behind the scenes.",
        "Initially, I started learning HTML and CSS through online tutorials. The ability to create something visual and interactive was incredibly exciting. Each line of code felt like a brushstroke on a digital canvas.",
        "Over time, I expanded my skills to JavaScript, which opened up a whole new world of possibilities. React became my framework of choice, allowing me to build complex, interactive web applications with elegant, reusable components.",
        "The most important lesson I've learned is that web development is not just about writing code, but about solving problems and creating experiences that help people."
      ]
    },
    2: {
      title: "Exploring AI and Machine Learning",
      date: "February 15, 2025",
      readTime: "7 min read",
      image: "/api/placeholder/1200/600", // Replace with actual image path
      content: [
        "Artificial Intelligence is no longer a distant dream but a rapidly evolving reality that's transforming every industry.",
        "From natural language processing to computer vision, AI technologies are becoming increasingly sophisticated. The potential applications are vast - from healthcare diagnostics to personalized education.",
        "As a developer, I'm particularly excited about how AI can be integrated into existing software systems to create more intelligent, adaptive experiences.",
        "The key is not just to implement AI, but to do so ethically and with a clear understanding of its potential impact on society."
      ]
    },
    3: {
      title: "Building Scalable React Applications",
      date: "January 20, 2025",
      readTime: "6 min read",
      image: "/api/placeholder/1200/600", // Replace with actual image path
      content: [
        "Scalability is a critical consideration when building React applications. It's not just about writing code that works today, but code that can grow and adapt tomorrow.",
        "One of the most important principles is component composition. By breaking down your application into small, reusable components, you create a more maintainable and flexible codebase.",
        "State management is another crucial aspect. Libraries like Redux or React Context can help manage complex application states more effectively.",
        "Always think about performance. Techniques like code splitting, lazy loading, and memoization can significantly improve your application's responsiveness."
      ]
    }
  };

  const { id } = useParams();
  const post = blogPosts[id];

  if (!post) {
    return <div className="blog-post-container">Post not found</div>;
  }

  const handleGoBack = () => {
    navigate('/blog');
  };

  return (
    <div className="blog-post-container">
      <div className="back-button-container">
        <button 
          className="btn" 
          onClick={handleGoBack}
        >
          Back to Blog
        </button>
      </div>

      <div className="blog-post-header">
        <img 
          src={post.image} 
          alt={post.title} 
          className="blog-post-hero-image" 
        />
        <h1>{post.title}</h1>
        <div className="blog-post-meta">
          <span>{post.date}</span>
          <span className="read-time">{post.readTime}</span>
        </div>
      </div>
      
      <div className="blog-post-content">
        {post.content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

export default BlogPost;